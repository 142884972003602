import React from 'react';
import { useDispatch } from 'react-redux';
import { TopMenu } from '../components/TopMenu';
import { updateDeviceStates } from '../actions/UpdateDeviceStates';
import { setDefinitions } from '../actions/SetDefinitions';
import { zigbeeAttributeUpdated } from '../features/zigbee/ZigbeeAttributesSlice';
import { parseAttribute } from '../types/Attribute';
import { parse } from '../util/parse';
import { DebugPanel } from './DebugPanel';
export const AppView = props => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        let api_url;
        if (process.env.NODE_ENV !== 'production') {
            //api_url = 'http://cosmo.int.zigo.org:8081/api';
            api_url = 'https://light.dev.zigo.org/api';
        }
        else {
            api_url = '/api';
        }
        let eventSource;
        let reconnect = true;
        let reconnectDelay = 1;
        let reconnectTimeoutId;
        function processAttribute(a, log_enabled) {
            const attr_input = {
                attribute_id: a.attribute_id,
                changed: a.ts,
                type: a.type,
                value: a.value
            };
            try {
                const attr = parse(parseAttribute, attr_input);
                if (log_enabled) {
                    console.log('attr', Object.assign(Object.assign({}, attr), { nid: a.node_id, eid: a.endpoint_id, cid: a.cluster_id }));
                }
                dispatch(zigbeeAttributeUpdated(a.node_id, a.endpoint_id, a.cluster_id, attr));
            }
            catch (e) {
                if (e instanceof Error) {
                    console.error('Could not parse attribute: ', attr_input, e.message, e);
                }
            }
        }
        function startEventSource() {
            eventSource = new EventSource(api_url + '/events', { withCredentials: true });
            // firefox give a warning about interrupting the events when reloading the page unless we close it when leaving the page
            window.addEventListener('beforeunload', () => { if (eventSource) {
                eventSource.close();
                eventSource = null;
            } });
            eventSource.addEventListener('zigbee attribute', e => {
                var _a;
                var data = JSON.parse(e.data);
                if (data) {
                    const log_enabled = ((_a = localStorage.getItem('log_event_zigbee_attributes')) !== null && _a !== void 0 ? _a : '0') == '1';
                    if (Array.isArray(data)) {
                        for (const element of data) {
                            processAttribute(element, log_enabled);
                        }
                    }
                    else {
                        processAttribute(data, log_enabled);
                    }
                }
            });
            eventSource.addEventListener('device states', e => {
                var _a;
                var data = JSON.parse(e.data);
                if (data) {
                    const log_enabled = ((_a = localStorage.getItem('log_event_device_states')) !== null && _a !== void 0 ? _a : '0') == '1';
                    if (log_enabled) {
                        console.log('state', data);
                    }
                    dispatch(updateDeviceStates(data));
                }
            });
            eventSource.addEventListener('definitions', e => {
                var _a;
                var data = JSON.parse(e.data);
                if (data) {
                    dispatch(setDefinitions(data));
                    if (Object.prototype.hasOwnProperty.call(data, 'device_states')) {
                        const log_enabled = ((_a = localStorage.getItem('log_event_definitions')) !== null && _a !== void 0 ? _a : '0') == '1';
                        if (log_enabled) {
                            console.log('def', data);
                        }
                        dispatch(updateDeviceStates(data.device_states));
                    }
                }
            });
            eventSource.onopen = () => {
                reconnectDelay = 1;
            };
            eventSource.onerror = () => {
                if (reconnect && eventSource.readyState == 2) {
                    reconnectTimeoutId = setTimeout(startEventSource, reconnectDelay * 1000);
                    if (reconnectDelay < 120) {
                        reconnectDelay *= 2;
                    }
                }
            };
        }
        startEventSource();
        return () => {
            reconnect = false;
            clearTimeout(reconnectTimeoutId);
            eventSource.close();
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(DebugPanel, null),
        React.createElement(TopMenu, null),
        props.children));
};
