import React from 'react';
import { Range } from './Range';
// hard code to 154 ~ 6500K and 454 ~ 2200K
export const ZigbeeColorTemperature = (props) => {
    const temp = 454 - props.temperature + 154; // invert value so the darkest is to the left
    function onChange(event) {
        event.target.valueAsNumber = 454 - event.target.valueAsNumber + 154;
        props.onChange(event);
    }
    return (React.createElement("div", { className: "zigbeecolortemperature" },
        React.createElement(Range, { min: 154, max: 454, value: temp, onChange: onChange })));
};
