import React from 'react';
import { useAppDispatch, useZigbeeDevice } from '../hooks';
import { DeviceButton2 } from '../components/DeviceButton';
import { zigbeeOn } from '../actions/ZigbeeOn';
import { zigbeeOff } from '../actions/ZigbeeOff';
import { useAttributeState } from '../features/zigbee/ZigbeeAttributesSlice';
export const DeviceButtonsZigbee = props => {
    const attrVal = useAttributeState(props.node_id, props.endpoint_id, 6, 0); // onoff value
    const device = useZigbeeDevice(props.node_id, props.endpoint_id);
    const dispatch = useAppDispatch();
    if (device == undefined) {
        return null;
    }
    let s1, s2;
    if ((attrVal === null || attrVal === void 0 ? void 0 : attrVal.value) == '1') {
        s1 = undefined;
        s2 = 'on';
    }
    else if ((attrVal === null || attrVal === void 0 ? void 0 : attrVal.value) == '0') {
        s1 = 'off';
        s2 = undefined;
    }
    else {
        s1 = undefined;
        s2 = undefined;
    }
    return (React.createElement("div", { className: "transmitter-buttons lw-button-group" },
        React.createElement(DeviceButton2, { state: s1, onClick: () => dispatch(zigbeeOff(device.id)) }, "Av"),
        React.createElement(DeviceButton2, { state: s2, onClick: () => dispatch(zigbeeOn(device.id)) }, "P\u00E5")));
};
