import React from 'react';
import { useAppSelector } from '../hooks';
import { DeviceButtonsZigbee } from '../components/DeviceButtonsZigbee';
import { DeviceButtonsNexaOnOff } from '../components/DeviceButtonsNexaOnOff';
import { DeviceButtonsNexaDim } from '../components/DeviceButtonsNexaDim';
import { DeviceButtonsHasta } from '../components/DeviceButtonsHasta';
export const DeviceButtons = (props) => {
    const devices = useAppSelector(state => state.definitions.devices);
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return null;
    }
    if (device.transmitter_kind == 'rollershade') {
        return React.createElement(DeviceButtonsHasta, { device_id: props.device_id });
    }
    else if (device.transmitter_kind == 'dim') {
        return React.createElement(DeviceButtonsNexaDim, { device_id: props.device_id });
    }
    else if (device.transmitter_kind == 'onoff' && device.protocol.name == 'zigbee') {
        return React.createElement(DeviceButtonsZigbee, { node_id: device.protocol.zigbee_node_id, endpoint_id: device.protocol.endpoint_id });
    }
    else if (device.transmitter_kind == 'onoff') {
        return React.createElement(DeviceButtonsNexaOnOff, { device_id: props.device_id });
    }
    else {
        console.log('Invalid DeviceButtons kind ' + device.transmitter_kind);
        return null;
    }
};
