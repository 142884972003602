import React, { useState } from 'react';
export const DebugPanelFrame = (props) => {
    const [showPanel, setShowPanel] = useState(false);
    if (process.env.NODE_ENV !== 'development')
        return null;
    const toggleShow = () => {
        setShowPanel(!showPanel);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                zIndex: '1',
                position: 'fixed',
                right: '0px',
                bottom: '0px',
                display: 'flex',
            } },
            React.createElement("div", { style: {
                    alignSelf: 'flex-end',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    backgroundColor: '#228866',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '2em',
                    height: '5em',
                    writingMode: 'sideways-lr',
                    borderTopLeftRadius: '10px',
                    cursor: 'pointer',
                }, onClick: toggleShow }, "Debug"),
            React.createElement("div", { style: {
                    display: showPanel ? 'block' : 'none',
                    backgroundColor: '#bbffdd',
                    height: 'auto',
                    width: 'auto',
                    border: '5px solid #228866',
                    borderTopLeftRadius: '20px',
                    padding: '8px',
                    overflow: 'hidden'
                } }, props.children))));
};
