import Ajax from '../util/ajax';
export function zigbeeAttributeReportingDefault(nid, eid, cid, aid, atype) {
    return dispatch => {
        const ajax = new Ajax(dispatch, true);
        const url = '/zigbee/' + nid + '/ep/' + eid + '/cluster/' + cid + '/reporting';
        ajax.post(url, [
            {
                op: 'default',
                attribute: aid,
                type: atype
            }
        ]);
    };
}
