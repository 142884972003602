import { useDispatch, useSelector, useStore } from 'react-redux';
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
export const useAppStore = useStore;
export function useZigbeeDevice(node_id, endpoint_id) {
    const devices = useAppSelector(state => state.definitions.devices);
    // not the most efficient. we need to use a lookup table to find the correct device faster
    for (const d of devices.values()) {
        if (d.protocol.name == 'zigbee' && d.protocol.zigbee_node_id == node_id && d.protocol.endpoint_id == endpoint_id) {
            return d;
        }
    }
}
