import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';
const initialState = {};
export const zigbeeAttributesSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {
        zigbeeAttributeUpdated: {
            reducer: (s, action) => {
                s[action.payload.key] = {
                    type: action.payload.type,
                    value: action.payload.value,
                    ts: action.payload.ts
                };
            },
            prepare: (nid, eid, cid, a) => {
                return {
                    payload: {
                        key: zigbeeAttributeLookupKey(nid, eid, cid, a.attribute_id),
                        type: a.type,
                        value: a.value,
                        ts: a.changed
                    }
                };
            }
        }
    }
});
function zigbeeAttributeLookupKey(nid, eid, cid, aid) {
    return nid.toString() + ' ' + eid.toString() + ' ' + cid.toString() + ' ' + aid.toString();
}
export function useAttributeState(nid, eid, cid, aid) {
    const key = zigbeeAttributeLookupKey(nid, eid, cid, aid);
    const attr = useAppSelector(state => state.zigbee.attributes[key]);
    return attr;
}
export const { zigbeeAttributeUpdated } = zigbeeAttributesSlice.actions;
export default zigbeeAttributesSlice.reducer;
