import React, { useState } from 'react';
export const Range = (props) => {
    const [valueDuringUserInput, setValueDuringUserInput] = useState(props.value);
    const [userIsMovingSliderTimeout, setUserIsMovingSliderTimeout] = useState(undefined);
    let currVal;
    if (userIsMovingSliderTimeout != undefined) {
        currVal = valueDuringUserInput;
    }
    else {
        currVal = props.value;
    }
    function onChange(event) {
        setValueDuringUserInput(event.target.valueAsNumber);
        if (userIsMovingSliderTimeout != undefined) {
            clearTimeout(userIsMovingSliderTimeout);
        }
        setUserIsMovingSliderTimeout(setTimeout(() => {
            setUserIsMovingSliderTimeout(undefined);
        }, 5000));
        props.onChange(event);
    }
    return React.createElement("input", { type: "range", min: props.min, max: props.max, value: currVal, style: { width: '100%' }, onChange: onChange });
};
