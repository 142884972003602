import React, { useState } from 'react';
import { useAppDispatch } from '../hooks';
import { DebugPanelFrame } from '../DebugPanelFrame';
function useBoolLocalStorage(key) {
    const init = () => {
        var _a;
        return ((_a = localStorage.getItem(key)) !== null && _a !== void 0 ? _a : '0') == '1';
    };
    const [value, setValueState] = useState(init);
    const setValue = (v) => {
        setValueState(v);
        localStorage.setItem(key, v ? '1' : '0');
    };
    return [value, setValue];
}
const LocalStorageCheckbox = (props) => {
    const [value, setValue] = useBoolLocalStorage(props.name);
    return (React.createElement("label", null,
        React.createElement("input", { type: "checkbox", checked: value, onChange: e => setValue(e.target.checked) }),
        "\u00A0",
        props.name));
};
const outputState = (dispatch, getState) => {
    const s = getState();
    console.log(s);
};
const outputDefinitions = (dispatch, getState) => {
    const s = getState();
    console.log(s.definitions);
};
export const DebugPanel = () => {
    const dispatch = useAppDispatch();
    return (React.createElement(DebugPanelFrame, null,
        React.createElement(LocalStorageCheckbox, { name: "log_device_state_changes" }),
        React.createElement("br", null),
        React.createElement(LocalStorageCheckbox, { name: "log_event_definitions" }),
        React.createElement("br", null),
        React.createElement(LocalStorageCheckbox, { name: "log_event_device_states" }),
        React.createElement("br", null),
        React.createElement(LocalStorageCheckbox, { name: "log_event_zigbee_attributes" }),
        React.createElement("br", null),
        React.createElement(LocalStorageCheckbox, { name: "log_redux_state_changes" }),
        React.createElement("hr", null),
        React.createElement("button", { onClick: () => dispatch(outputDefinitions) }, "Output definitions"),
        "\u00A0",
        React.createElement("button", { onClick: () => dispatch(outputState) }, "Output state")));
};
