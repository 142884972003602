import { parseInteger, parseObject, parseEnum, parseValidate } from '../util/parse';
import { parseWeekdays } from './Weekdays';
export function isSunType(s) {
    return ['t', 'u', 'e', 'n'].includes(s);
}
export function isScheduleRandom(s) {
    return ['r0', 'r5', 'r15'].includes(s);
}
const parseSunType = parseEnum(['t', 'u', 'e', 'n']);
const parseSunAjust = parseValidate(parseInteger, i => {
    if (i >= -3 && i <= 3) {
        return null;
    }
    else {
        return 'The value is ' + i + ', must be between -3 and 3';
    }
});
const parseScheduleRandom = parseEnum(['r0', 'r5', 'r15']);
export const parseSun = parseObject('Sun', {
    k: parseSunType,
    a: parseSunAjust,
    w: parseWeekdays,
    r: parseScheduleRandom,
});
