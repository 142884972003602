import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Link } from 'react-router-dom';
import { createZigbee } from '../actions/CreateZigbee';
import { saveZigbee } from '../actions/SaveZigbee';
import { fetchZigbeeNodeEndpointGroups } from '../actions/FetchZigbeeNodeEndpointGroups';
import { clusterName, clusterNameOrHex } from '../zigbee/cluster';
import { ConfigZigbeeEndpointAttributes } from '../components/ConfigZigbeeEndpointAttributes';
import { ZigbeeNodeEndpointGroups } from '../components/ZigbeeNodeEndpointGroups';
import { Group } from '../components/Group';
export const ConfigZigbeeEndpoint = (props) => {
    const dispatch = useAppDispatch();
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const devices = useAppSelector(state => state.definitions.devices);
    const node = zigbee_nodes.get(props.node);
    if (node == undefined) {
        return null;
    }
    const endpoint = node.endpoints.get(props.endpoint);
    if (endpoint == undefined) {
        return null;
    }
    const endpointPredicate = (d) => {
        return d.protocol.name == 'zigbee'
            && d.protocol.zigbee_node_id == node.id
            && d.protocol.endpoint_id == endpoint.endpoint;
    };
    const endpointDevices = Array.from(devices.values()).filter(endpointPredicate);
    let device_id = null;
    let device_title = '';
    let device = endpointDevices[0];
    if (device != undefined) {
        device_id = device.id;
        device_title = device.title;
    }
    const [title, setTitle] = React.useState(device_title);
    const onChange = event => {
        setTitle(event.target.value);
    };
    const onSubmit = event => {
        event.preventDefault();
        if (device_id) {
            dispatch(saveZigbee(device_id, title));
        }
        else {
            dispatch(createZigbee(props.node, props.endpoint, title));
        }
    };
    const clusterTooltip = c => {
        let clusterStr = clusterName(c);
        if (clusterStr != undefined)
            return clusterStr + ' id=0x' + c.toString(16).padLeft(4, '0');
        else
            return undefined;
    };
    const input_clusters = endpoint.input_clusters.map(c => React.createElement("span", { key: c, title: clusterTooltip(c) },
        clusterNameOrHex(c),
        " "));
    const output_clusters = endpoint.output_clusters.map(c => React.createElement("span", { key: c, title: clusterTooltip(c) },
        clusterNameOrHex(c),
        " "));
    const deviceElem = React.createElement(Link, { to: '/device/' + device_id },
        React.createElement("span", { style: { color: 'green' } },
            " ",
            device_title));
    const profile = endpoint.profile_name ? endpoint.profile_name : endpoint.profile;
    const zigbee_device = endpoint.device_name ? endpoint.device_name : '-';
    return (React.createElement(Group, { title: 'Endpoint: ' + props.endpoint },
        React.createElement("div", null,
            profile,
            " ",
            zigbee_device),
        React.createElement("div", null,
            "input=",
            input_clusters),
        React.createElement("div", null,
            "output=",
            output_clusters),
        React.createElement("div", null,
            device_id,
            " ",
            deviceElem),
        React.createElement("form", { onSubmit: onSubmit },
            React.createElement("input", { type: "text", size: 30, value: title, onChange: onChange })),
        React.createElement(ConfigZigbeeEndpointAttributes, { node: props.node, endpoint: props.endpoint }),
        React.createElement(ZigbeeNodeEndpointGroups, { groups: endpoint.groups, onFetchEndpointGroups: () => dispatch(fetchZigbeeNodeEndpointGroups(props.node, props.endpoint)) })));
};
