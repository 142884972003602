import Ajax from '../util/ajax';
import { updateTransmitterStates } from '../actions/UpdateTransmitterStates';
export function zigbeeColorTemperature(node_id, endpoint_id, value) {
    return dispatch => {
        const success = data => {
            if (data.states) {
                dispatch(updateTransmitterStates(data.states));
            }
        };
        const ajax = new Ajax(dispatch);
        ajax.throttle_post('/zigbee/' + node_id + '/ep/' + endpoint_id + '/temperature', { level: value }, success);
    };
}
