import React from 'react';
import { useAttributeState } from './ZigbeeAttributesSlice';
function renderAttributeValue(a) {
    if (typeof a.value == 'undefined') {
        return '?';
    }
    else if (a.type == 'Bool') {
        return a.value ? '\u2705' : '\u274C';
    }
    else {
        return a.value.toString();
    }
}
export const ZigbeeAttributeValue = (props) => {
    const attr = useAttributeState(props.nid, props.eid, props.cid, props.aid);
    if (attr != undefined) {
        const val = attr ? renderAttributeValue(attr) : '?';
        return (React.createElement("span", null, val));
    }
};
