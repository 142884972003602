import React from 'react';
import { useAppSelector } from '../hooks';
import { DeviceButtons } from '../components/DeviceButtons';
import * as LWIcon from '../components/LWIcon';
import { DeviceLink } from '../features/devices/DeviceLink';
export const PanelDevice = (props) => {
    const devices = useAppSelector(state => state.definitions.devices);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return (React.createElement("div", null, "Unknown device"));
    }
    let hasDimmer = false;
    if (device.protocol.name == 'zigbee') {
        const node_id = device.protocol.zigbee_node_id;
        const eid = device.protocol.endpoint_id;
        const zigbee_node = zigbee_nodes.get(node_id);
        if (zigbee_node != undefined) {
            const zigbee_endpoint = zigbee_node.endpoints.get(eid);
            if (zigbee_endpoint != undefined) {
                hasDimmer = zigbee_endpoint.gui.level != undefined;
            }
        }
    }
    let dimmerSymbol;
    if (hasDimmer) {
        dimmerSymbol = (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { color: '#aca' } },
                React.createElement(LWIcon.Dimmer, null)),
            "\u00A0"));
    }
    else {
        dimmerSymbol = '';
    }
    return (React.createElement("div", { style: { display: 'flex' }, className: "panel-transmitter" },
        React.createElement("div", { style: { flex: 'auto', overflow: 'hidden' } },
            React.createElement(DeviceLink, { device_id: props.device_id })),
        React.createElement("div", { style: { flex: 'initial', display: 'flex' } },
            dimmerSymbol,
            React.createElement(DeviceButtons, { device_id: props.device_id }))));
};
