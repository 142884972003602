import Ajax from '../util/ajax';
export function zigbeeAttributeReportingSet(nid, eid, cid, aid, atype, min, max, change) {
    return dispatch => {
        const ajax = new Ajax(dispatch, true);
        const url = '/zigbee/' + nid + '/ep/' + eid + '/cluster/' + cid + '/reporting';
        ajax.post(url, [
            {
                op: 'set',
                attribute: aid,
                type: atype,
                min,
                max,
                change
            }
        ]);
    };
}
