import React from 'react';
import { useAppSelector, useAppDispatch, useZigbeeDevice } from '../hooks';
import { DeviceButtonsZigbee } from '../components/DeviceButtonsZigbee';
import { ZigbeeLevelControl } from '../components/ZigbeeLevelControl';
import { ZigbeeColorTemperature } from '../components/ZigbeeColorTemperature';
import { zigbeeLevelControl } from '../actions/ZigbeeLevelControl';
import { zigbeeColorTemperature } from '../actions/ZigbeeColorTemperature';
import { useAttributeState } from '../features/zigbee/ZigbeeAttributesSlice';
const Level = (props) => {
    const dispatch = useAppDispatch();
    const attrLevel = useAttributeState(props.node_id, props.endpoint_id, 8, 0);
    const onChangeLevelControl = (event) => {
        const val = event.target.value;
        dispatch(zigbeeLevelControl(props.node_id, props.endpoint_id, val));
    };
    if (props.show && attrLevel != undefined) {
        const levelStr = attrLevel.value;
        const levelValue = levelStr == undefined ? 127 : parseInt(levelStr);
        const percent = Math.round((levelValue - 1) / 254 * 100);
        return React.createElement(React.Fragment, null,
            "Ljusstyrka (",
            percent,
            "%): ",
            React.createElement(ZigbeeLevelControl, { onChange: onChangeLevelControl, level: levelValue }));
    }
    else {
        return null;
    }
};
const ColorTemperature = (props) => {
    const dispatch = useAppDispatch();
    const attrColorTemp = useAttributeState(props.node_id, props.endpoint_id, 0x300, 7);
    const onChangeColorTemperature = (event) => {
        const val = event.target.value;
        dispatch(zigbeeColorTemperature(props.node_id, props.endpoint_id, val));
    };
    if (props.show && attrColorTemp != undefined) {
        const temperatureStr = attrColorTemp.value;
        const temperature = temperatureStr == undefined ? 454 : parseInt(temperatureStr);
        // the value is hard coded to: 154 ~ 6500K and 454 ~ 2200K
        const kelvin = Math.round(6500 - ((6500 - 2200) * (temperature - 154) / (454 - 154)));
        return React.createElement(React.Fragment, null,
            "F\u00E4rgtemperatur (",
            kelvin,
            "K): ",
            React.createElement(ZigbeeColorTemperature, { onChange: onChangeColorTemperature, temperature: temperature }));
    }
    else {
        return null;
    }
};
export const DeviceZigbee = props => {
    const device = useZigbeeDevice(props.node_id, props.endpoint_id);
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    if (device == undefined) {
        console.error('No device for zigbee_node_id=' + props.node_id + ' endpoint_id=' + props.endpoint_id);
        return (React.createElement("div", null, "Error"));
    }
    if (device.protocol.name != 'zigbee') {
        console.error('Not a zigbee device', device.protocol.name);
        return (React.createElement("div", null, "Error"));
    }
    const zigbee_id = device.protocol.zigbee_node_id;
    const eid = device.protocol.endpoint_id;
    const zigbee_node = zigbee_nodes.get(zigbee_id);
    const zigbee_endpoint = zigbee_node === null || zigbee_node === void 0 ? void 0 : zigbee_node.endpoints.get(eid);
    if (zigbee_endpoint == undefined) {
        console.error('Unknown zigbee endpoint: ', eid);
        return (React.createElement("div", null, "Error"));
    }
    const has_level = 'level' in zigbee_endpoint.gui && zigbee_endpoint.gui.level != undefined;
    const has_color_temperature = 'temperature' in zigbee_endpoint.gui && zigbee_endpoint.gui.temperature != undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(DeviceButtonsZigbee, { node_id: zigbee_id, endpoint_id: zigbee_endpoint.endpoint }),
        React.createElement("div", { style: { paddingTop: '10px' } },
            React.createElement(Level, { device_id: device.id, node_id: zigbee_id, endpoint_id: zigbee_endpoint.endpoint, show: has_level }),
            React.createElement(ColorTemperature, { device_id: device.id, node_id: zigbee_id, endpoint_id: zigbee_endpoint.endpoint, show: has_color_temperature }))));
};
