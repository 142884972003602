export function attributeTypeKind(a) {
    switch (a) {
        case 'NoData': return 'NoKind';
        case 'Data8': return 'Digital';
        case 'Data16': return 'Digital';
        case 'Data24': return 'Digital';
        case 'Data32': return 'Digital';
        case 'Data40': return 'Digital';
        case 'Data48': return 'Digital';
        case 'Data56': return 'Digital';
        case 'Data64': return 'Digital';
        case 'Bool': return 'Digital';
        case 'Bitmap8': return 'Digital';
        case 'Bitmap16': return 'Digital';
        case 'Bitmap24': return 'Digital';
        case 'Bitmap32': return 'Digital';
        case 'Bitmap40': return 'Digital';
        case 'Bitmap48': return 'Digital';
        case 'Bitmap56': return 'Digital';
        case 'Bitmap64': return 'Digital';
        case 'UInt8': return 'Analog';
        case 'UInt16': return 'Analog';
        case 'UInt24': return 'Analog';
        case 'UInt32': return 'Analog';
        case 'UInt40': return 'Analog';
        case 'UInt48': return 'Analog';
        case 'UInt56': return 'Analog';
        case 'UInt64': return 'Analog';
        case 'Int8': return 'Analog';
        case 'Int16': return 'Analog';
        case 'Int24': return 'Analog';
        case 'Int32': return 'Analog';
        case 'Int40': return 'Analog';
        case 'Int48': return 'Analog';
        case 'Int56': return 'Analog';
        case 'Int64': return 'Analog';
        case 'Enum8': return 'Digital';
        case 'Enum16': return 'Digital';
        case 'FloatSemi': return 'Analog';
        case 'FloatSingle': return 'Analog';
        case 'FloatDouble': return 'Analog';
        case 'OctetStr': return 'Digital';
        case 'CharStr': return 'Digital';
        case 'LongOctetStr': return 'Digital';
        case 'LongCharStr': return 'Digital';
        case 'Array': return 'Digital';
        case 'Struct': return 'Digital';
        case 'Set': return 'Digital';
        case 'Bag': return 'Digital';
        case 'TimeOfDay': return 'Analog';
        case 'Date': return 'Analog';
        case 'UTCTime': return 'Analog';
        case 'ClusterID': return 'Digital';
        case 'AttributeID': return 'Digital';
        case 'BACnetOID': return 'Digital';
        case 'IEEEAddr': return 'Digital';
        case 'Security128': return 'Digital';
        case 'Unknown': return 'NoKind';
    }
}
